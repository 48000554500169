import 'trumbowyg/dist/plugins/table/trumbowyg.table';
import 'trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize';
import 'trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily';
import 'trumbowyg/dist/ui/trumbowyg.css';
import 'trumbowyg/dist/plugins/table/ui/trumbowyg.table.min.css';
import 'trumbowyg/dist/plugins/cleanpaste/trumbowyg.cleanpaste.min.js';

export default {
  getConfig() {
    return {
      btns: [
        ['viewHTML'],
        ['template'],
        ['undo', 'redo'],
        ['fontsize'],
        ['formatting'],
        ['strong', 'em'],
        ['table'],
        ['link'],
        ['insertImage'],
        ['textAlign'],
        ['unorderedList', 'orderedList'],
        ['horizontalRule'],
        ['removeformat'],
        ['fullscreen']
      ],
      plugins: {
        // TODO
      },
      btnsDef: {
        textAlign: {
          dropdown: ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          title: 'Text Align',
          ico: 'justifyLeft'
        }
      },
      resetCss: true,
      removeformatPasted: true
    };
  }
};
